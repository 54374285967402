import { Layout } from "../model/Layout";

type PositionParams = ({ left: number } | { right: number }) &
  ({ top: number } | { bottom: number });

type DrawTextParams = PositionParams & {
  text: string;
  fontSize: number;
  fontFamily: string;
  color: string;
  shadowColor?: string;
  shadowBlur?: number;
};

export const drawText = (
  ctx: CanvasRenderingContext2D,
  layoutOptions: Layout,
  params: DrawTextParams
) => {
  const { text, fontSize, fontFamily, color, shadowColor, shadowBlur } = params;

  let x, y, textAlign: CanvasTextAlign;

  if ("left" in params) {
    x = params.left;
    textAlign = "left";
  } else {
    x = layoutOptions.dimensions.width - params.right;
    textAlign = "right";
  }

  if ("top" in params) {
    y = params.top + fontSize;
  } else {
    y = layoutOptions.dimensions.height - params.bottom;
  }

  ctx.textAlign = textAlign;
  ctx.font = ["normal", fontSize + "px", fontFamily].join(" ");
  ctx.fillStyle = color;

  if (shadowColor) ctx.shadowColor = shadowColor;
  if (shadowBlur) ctx.shadowBlur = shadowBlur;

  ctx.fillText(text, x, y);
};
