import React, { useState } from "react";
import {
  optionsSchema,
  SelectOptionSchema,
  defaultOptions,
  optionGroups,
} from "../../model/Options";
import "twin.macro";
import { colours } from "../../model/Colours";

type OptionComponentProps = {
  optionKey: string;
  optionSchema: SelectOptionSchema;
  optionValue: string;
  setOption(key: string, value: string): void;
};

const SelectOption = ({
  optionKey,
  optionSchema,
  optionValue,
  setOption,
}: OptionComponentProps) => {
  return (
    <div>
      {optionSchema.options.map((option) => {
        const id = optionSchema.name + "-" + option;
        return (
          <div key={option}>
            <input
              type="radio"
              name={optionSchema.name}
              id={id}
              value={option}
              checked={option === optionValue}
              onChange={() => setOption(optionKey, option)}
            />{" "}
            <label htmlFor={id}>{option}</label>
          </div>
        );
      })}
    </div>
  );
};

const ColourOption = ({
  optionKey,
  optionSchema,
  optionValue,
  setOption,
}: OptionComponentProps) => {
  return (
    <div>
      {Object.entries(colours).map(([key, { code }]) => {
        const id = optionSchema.name + "-" + key;
        const selected = optionValue === key;
        return (
          <div key={key} tw="inline-block">
            <input
              type="radio"
              name={optionSchema.name}
              id={id}
              value={key}
              checked={selected}
              onChange={() => setOption(optionKey, key)}
              tw="hidden"
            />
            <label htmlFor={id}>
              <div
                style={{ backgroundColor: code, borderWidth: selected ? 1 : 0 }}
                tw="h-4 w-4 inline-block mr-2 rounded-full hover:shadow border-black"
              />
            </label>
          </div>
        );
      })}
    </div>
  );
};

const OptionsGroup = ({ group, options, setOption }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div tw="border-b last:border-b-0 border-gray-500 pb-2 mb-2">
      <div tw="font-bold">
        <input
          id={group.id}
          type="checkbox"
          checked={isOpen}
          onChange={() => setIsOpen(!isOpen)}
          tw="hidden"
        />
        <label htmlFor={group.id}>
          {isOpen ? "v" : ">"} {group.title}
        </label>
      </div>
      {isOpen &&
        group.options.map((optionKey) => {
          const optionSchema = optionsSchema[optionKey];

          const optionValue = options[optionKey];

          let OptionComponent;

          switch (optionSchema.type) {
            case "select":
              OptionComponent = SelectOption;
              break;
            case "colour":
              OptionComponent = ColourOption;
              break;
          }

          return (
            <div key={optionKey} tw="border-b border-gray-300">
              <div>{optionSchema.name}</div>
              <div>
                <OptionComponent
                  optionKey={optionKey}
                  optionSchema={optionSchema}
                  optionValue={optionValue}
                  setOption={setOption}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

type ControlsSidebarProps = {
  options: typeof defaultOptions;
  setOption(key: string, value: string): void;
};

export const ControlsSidebar: React.FC<ControlsSidebarProps> = ({
  options,
  setOption,
}) => {
  return (
    <div tw="overflow-y-scroll h-full p-2" style={{ width: 260 }}>
      {optionGroups.map((group) => {
        return (
          <OptionsGroup
            key={group.id}
            group={group}
            options={options}
            setOption={setOption}
          />
        );
      })}
    </div>
  );
};
