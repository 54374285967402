export type Colour = {
  name: string;
  code: string;
};

export const colours = {
  white: {
    name: "White",
    code: "#ffffff",
  },
  black: {
    name: "Black",
    code: "#111827",
  },
  gray: {
    name: "Gray",
    code: "#A3A3A3",
  },
  red: {
    name: "Red",
    code: "#EF4444",
  },
  orange: {
    name: "Orange",
    code: "#F97316",
  },
  yellow: {
    name: "Yellow",
    code: "#FACC15",
  },
  green: {
    name: "Green",
    code: "#22C55E",
  },
  sky: {
    name: "Sky",
    code: "#0EA5E9",
  },
  purple: {
    name: "Purple",
    code: "#A855F7",
  },
  pink: {
    name: "Pink",
    code: "#EC4899",
  },
};
