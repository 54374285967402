import colourMapStyle from "../../map-styles/colour.json";
import darkMapStyle from "../../map-styles/dark.json";
import lightMapStyle from "../../map-styles/light.json";
import { colours } from "../../model/Colours";

export const getMapStyle = (options, feature) => {
  const mapStyles = {
    colour: colourMapStyle,
    dark: darkMapStyle,
    light: lightMapStyle,
  };

  const mapStyle = mapStyles[options.mapStyle];

  if (!mapStyle)
    throw new Error("Unexpected map style option: " + options.mapStyle);

  let layers = [...mapStyle.layers];
  let sources = { ...mapStyle.sources };

  // remove unwanted layers

  const layersToHide = [
    "greenspace names",
    "woodland names",
    "landcover names",
    "sites names",
    "contour labels",
  ];

  layers = layers.filter(({ id }) => !layersToHide.includes(id));

  // add hillshade source and layer

  const hillshadeSource = {
    type: "raster-dem",
    tiles: ["https://labs.os.uk/tiles/terrain-rgb/terrain50/{z}/{x}/{y}.png"],
    minzoom: 0,
    maxzoom: 9,
  };

  sources["terrain"] = hillshadeSource;

  const hillshadeLayer = {
    id: "hillshade",
    type: "hillshade",
    source: "terrain",
    paint: {
      "hillshade-exaggeration": 0.5,
    },
    layout: {
      visibility: options.hillshading === "true" ? "visible" : "none",
    },
  };

  const beforeId =
    options.mapStyle === "colour" ? "buildings 3D" : "primary road numbers";

  const beforeIndex = layers.findIndex(({ id }) => id === beforeId);

  layers.splice(beforeIndex, 0, hillshadeLayer);

  // add activity source and layers

  sources["activity"] = {
    type: "geojson",
    data: feature,
  };

  layers.push({
    id: "activity-line-secondary",
    source: "activity",
    type: "line",
    paint: {
      "line-color": colours[options.lineSecondaryColour].code,
      "line-width": 5,
      "line-opacity": 1,
    },
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
  });

  layers.push({
    id: "activity-line-primary",
    source: "activity",
    type: "line",
    paint: {
      "line-color": colours[options.linePrimaryColour].code,
      "line-width": 3,
      "line-opacity": 1,
    },
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
  });

  return {
    ...mapStyle,
    layers,
    sources,
  };
};
