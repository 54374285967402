import { colours } from "./Colours";

type OptionSchemaBase = {
  name: string;
};

export type SelectOptionSchema = OptionSchemaBase & {
  type: "select";
  options: string[];
};

export type ColourOptionSchema = OptionSchemaBase & {
  type: "colour";
};

type OptionSchema = SelectOptionSchema | ColourOptionSchema;

export const optionsSchema: { [key: string]: OptionSchema } = {
  layout: {
    type: "select",
    name: "Size",
    options: ["square", "vertical"],
  },
  mapStyle: {
    type: "select",
    name: "Style",
    options: ["colour", "light", "dark"],
  },
  hillshading: {
    type: "select",
    name: "Hillshading",
    options: ["true", "false"],
  },
  title: {
    type: "select",
    name: "Show Title",
    options: ["true", "false"],
  },
  font: {
    type: "select",
    name: "Font",
    options: [
      "Inter",
      "Georgia",
      "Arial",
      "Times New Roman",
      "Courier New",
      "Comic Sans MS",
    ],
  },
  linePrimaryColour: {
    type: "colour",
    name: "Primary Colour",
  },
  lineSecondaryColour: {
    type: "colour",
    name: "Secondary Colour",
  },
  textPrimaryColour: {
    type: "colour",
    name: "Primary Colour",
  },
  textSecondaryColour: {
    type: "colour",
    name: "Secondary Colour",
  },
  textEffect: {
    type: "select",
    name: "Effect",
    options: ["none", "shadow", "background", "offset"],
  },
};

export const defaultOptions: { [key: keyof typeof optionsSchema]: string } = {
  ...Object.fromEntries(
    Object.entries(optionsSchema).map(([key, schema]) => [
      key,
      schema.type === "select" ? schema.options[0] : Object.keys(colours)[0],
    ])
  ),
  textPrimaryColour: "black",
  linePrimaryColour: "red",
};

export const optionGroups = [
  {
    id: "layout",
    title: "Layout",
    options: ["layout", "title"],
  },
  {
    id: "map",
    title: "Map",
    options: ["mapStyle", "hillshading"],
  },
  {
    id: "line",
    title: "Line",
    options: ["linePrimaryColour", "lineSecondaryColour"],
  },
  {
    id: "text",
    title: "Text",
    options: ["font", "textPrimaryColour", "textEffect", "textSecondaryColour"],
  },
];
