import React, { useEffect } from "react";
import { Activity } from "../../model/Activity";
import { colours } from "../../model/Colours";
import { Layout } from "../../model/Layout";
import { defaultOptions } from "../../model/Options";
import { drawText } from "../../utils/canvasUtils";
import { getPaddingValue } from "../../utils/getPaddingValue";
import { secondsToDurationString } from "../../utils/secondsToDurationString";

interface OverlayProps {
  activity: Activity;
  options: typeof defaultOptions;
  layoutOptions: Layout;
  canvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
}

const Overlay: React.FC<OverlayProps> = ({
  activity,
  options,
  layoutOptions,
  canvasRef,
}) => {
  useEffect(() => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext("2d");
    if (!ctx) return;

    const defaultTextParams = {
      fontFamily: options.font,
      fontSize: 48,
      color: colours[options.textPrimaryColour].code,
      shadowColor: colours[options.textSecondaryColour].code,
      shadowBlur: 15,
    };

    ctx.clearRect(
      0,
      0,
      layoutOptions.dimensions.width,
      layoutOptions.dimensions.height
    );

    // Name

    if (options.title === "true")
      drawText(ctx, layoutOptions, {
        ...defaultTextParams,
        left: getPaddingValue(layoutOptions.outerPadding, "left"),
        top: getPaddingValue(layoutOptions.outerPadding, "top"),
        fontSize: layoutOptions.nameFontSize,
        text: new Intl.DateTimeFormat([], {
          dateStyle: "full",
          timeStyle: "short",
        }).format(activity.details.startDate),
      });

    // Distance

    drawText(ctx, layoutOptions, {
      ...defaultTextParams,
      left: getPaddingValue(layoutOptions.outerPadding, "left"),
      bottom:
        getPaddingValue(layoutOptions.outerPadding, "bottom") +
        layoutOptions.propertyValueFontSize,
      fontSize: layoutOptions.propertyLabelFontSize,
      text: `Distance`,
    });

    drawText(ctx, layoutOptions, {
      ...defaultTextParams,
      left: getPaddingValue(layoutOptions.outerPadding, "left"),
      bottom: getPaddingValue(layoutOptions.outerPadding, "bottom"),
      fontSize: layoutOptions.propertyValueFontSize,
      text: `${activity.details.length.toFixed(1)} km`,
    });

    // Time

    drawText(ctx, layoutOptions, {
      ...defaultTextParams,
      right: getPaddingValue(layoutOptions.outerPadding, "right"),
      bottom:
        getPaddingValue(layoutOptions.outerPadding, "bottom") +
        layoutOptions.propertyValueFontSize,
      fontSize: layoutOptions.propertyLabelFontSize,
      text: `Time`,
    });

    drawText(ctx, layoutOptions, {
      ...defaultTextParams,
      right: getPaddingValue(layoutOptions.outerPadding, "right"),
      bottom: getPaddingValue(layoutOptions.outerPadding, "bottom"),
      fontSize: layoutOptions.propertyValueFontSize,
      text: secondsToDurationString(activity.details.duration),
    });
  }, [activity, canvasRef, layoutOptions, options]);

  return (
    <canvas
      ref={canvasRef}
      height={layoutOptions.dimensions.height}
      width={layoutOptions.dimensions.width}
    />
  );
};

export default Overlay;
