export type PaddingObject = {
  top: number;
  left: number;
  right: number;
  bottom: number;
};

export type Padding = number | PaddingObject;

export interface Layout {
  dimensions: {
    width: number;
    height: number;
  };
  outerPadding: Padding;
  innerPadding: Padding;
  mapPixelRatio: number;
  nameFontSize: number;
  propertyLabelFontSize: number;
  propertyValueFontSize: number;
}

export const squareLayout: Layout = {
  dimensions: {
    width: 1080,
    height: 1080,
  },
  mapPixelRatio: 2.5,
  outerPadding: 30,
  innerPadding: {
    top: 30,
    bottom: 30,
    left: 0,
    right: 0,
  },
  nameFontSize: 56,
  propertyLabelFontSize: 40,
  propertyValueFontSize: 80,
};

export const verticalLayout: Layout = {
  dimensions: {
    width: 1080,
    height: 1920,
  },
  mapPixelRatio: 2.5,
  outerPadding: {
    top: 170,
    bottom: 170,
    left: 30,
    right: 30,
  },
  innerPadding: {
    top: 40,
    bottom: 40,
    left: 30,
    right: 30,
  },
  nameFontSize: 56,
  propertyLabelFontSize: 40,
  propertyValueFontSize: 80,
};
