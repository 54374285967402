import { gpx } from "@tmcw/togeojson";
import { Feature, LineString } from "geojson";
import { getActivityDetails } from "./getActivityDetails";

const parseGpx = (file: string) => {
  const parser = new DOMParser();
  const document = parser.parseFromString(file, "application/xml");

  const geojson = gpx(document);

  const feature = geojson.features.find(
    ({ geometry: { type } }) => type === "LineString"
  ) as Feature<LineString> | undefined;

  if (!feature) return null;

  const details = getActivityDetails(feature);

  return { feature, details };
};

export default parseGpx;
