import { Feature, LineString } from "geojson";
import { length } from "@turf/turf";
import { ActivityDetails } from "../model/Activity";

const getStartDate = (feature: Feature<LineString>) => {
  return new Date(feature.properties!.coordinateProperties.times[0]);
};

const getEndDate = (feature: Feature<LineString>) => {
  const { times } = feature.properties!.coordinateProperties;
  return new Date(times[times.length - 1]);
};

const getLength = (feature: Feature<LineString>) => {
  return length(feature);
};

export const getActivityDetails = (
  feature: Feature<LineString>
): ActivityDetails => {
  const startDate = getStartDate(feature);
  const endDate = getEndDate(feature);
  const duration = (endDate.getTime() - startDate.getTime()) / 1000;

  return {
    startDate,
    endDate,
    duration,
    length: getLength(feature),
  };
};
