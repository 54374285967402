import React from "react";
import readFile from "../../utils/readFile";
import parseGpx from "../../utils/parseGpx";
import { Activity } from "../../model/Activity";
import { DEMO_FILES } from "../../constants";

interface LandingPageProps {
  onActivityLoad(activity: Activity): any;
  onSelectDemoFile(file: keyof typeof DEMO_FILES): void;
}

const LandingPage: React.FunctionComponent<LandingPageProps> = ({
  onActivityLoad,
  onSelectDemoFile,
}) => {
  const handleFile: React.ChangeEventHandler<HTMLInputElement> = async ({
    target: { files },
  }) => {
    if (!files) return;

    const fileContents = await readFile(files[0]);

    const activity = parseGpx(fileContents);

    if (!activity) return;

    onActivityLoad(activity);
  };

  const handleSelectDemoFile = (file: keyof typeof DEMO_FILES) => {
    window.history.pushState({}, "", `/?${file}`);
    onSelectDemoFile(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFile} multiple={false} />
      <ul>
        {Object.keys(DEMO_FILES).map((file) => (
          <li key={file}>
            <button
              onClick={() =>
                handleSelectDemoFile(file as keyof typeof DEMO_FILES)
              }
            >
              {file}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LandingPage;
