import React, { useState, useEffect, useMemo } from "react";
import { Feature, LineString } from "geojson";
import ReactMapGL, { MapRef } from "react-map-gl";
import { bbox as turfBbox } from "@turf/turf";
import maplibregl from "maplibre-gl";
import WebMercatorViewport from "viewport-mercator-project";
import { Layout } from "../../model/Layout";
import { Activity } from "../../model/Activity";
import { getPaddingValue } from "../../utils/getPaddingValue";
import { defaultOptions } from "../../model/Options";
import { getMapStyle } from "./getMapStyle";
import "twin.macro";
import "maplibre-gl/dist/maplibre-gl.css";

const getViewport = (
  feature: Feature<LineString>,
  options: typeof defaultOptions,
  layoutOptions: Layout
) => {
  const bbox = turfBbox(feature);

  const padding = {
    bottom:
      getPaddingValue(layoutOptions.outerPadding, "top") +
      (options.title === "true" ? layoutOptions.nameFontSize : 0) +
      getPaddingValue(layoutOptions.innerPadding, "top"),
    left:
      getPaddingValue(layoutOptions.outerPadding, "left") +
      getPaddingValue(layoutOptions.innerPadding, "left"),
    right:
      getPaddingValue(layoutOptions.outerPadding, "right") +
      getPaddingValue(layoutOptions.innerPadding, "right"),
    top:
      getPaddingValue(layoutOptions.outerPadding, "bottom") +
      layoutOptions.propertyValueFontSize +
      layoutOptions.propertyLabelFontSize +
      getPaddingValue(layoutOptions.innerPadding, "bottom"),
  };

  const scaledPadding = Object.fromEntries(
    Object.entries(padding).map(([side, value]) => [
      side,
      value / layoutOptions.mapPixelRatio,
    ])
  ) as typeof padding;

  return new WebMercatorViewport({
    width: layoutOptions.dimensions.width / layoutOptions.mapPixelRatio,
    height: layoutOptions.dimensions.height / layoutOptions.mapPixelRatio,
  }).fitBounds(
    [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[3]],
    ],
    {
      padding: scaledPadding,
    }
  );
};

interface MapProps {
  activity: Activity;
  options: typeof defaultOptions;
  layoutOptions: Layout;
  mapRef: React.MutableRefObject<MapRef | null>;
}

const Map: React.FunctionComponent<MapProps> = ({
  activity: { feature },
  options,
  layoutOptions,
  mapRef,
}) => {
  const [viewport, setViewport] = useState(() =>
    getViewport(feature, options, layoutOptions)
  );

  useEffect(() => {
    Object.defineProperties(window, {
      devicePixelRatio: {
        get() {
          return layoutOptions.mapPixelRatio;
        },
      },
    });

    setViewport(getViewport(feature, options, layoutOptions));

    mapRef.current?.resize();
  }, [feature, layoutOptions.mapPixelRatio, layoutOptions, options, mapRef]);

  const mapStyle = useMemo(
    () => getMapStyle(options, feature),
    [options, feature]
  );

  return (
    <ReactMapGL
      {...viewport}
      mapLib={maplibregl}
      mapStyle={mapStyle}
      attributionControl={false}
      preserveDrawingBuffer={true}
      ref={mapRef}
      trackResize={false}
      style={{
        width: layoutOptions.dimensions.width / layoutOptions.mapPixelRatio,
        height: layoutOptions.dimensions.height / layoutOptions.mapPixelRatio,
        transform: `scale(${layoutOptions.mapPixelRatio})`,
        transformOrigin: "top left",
      }}
    />
  );
};

export default Map;
