import React, { useState, useRef } from "react";
import tw from "twin.macro";
import Map from "./Map";
import { Layout, verticalLayout, squareLayout } from "../../model/Layout";
import { MapRef } from "react-map-gl";
import { Activity } from "../../model/Activity";
import Overlay from "./Overlay";
import useResizeObserver from "use-resize-observer";
import { canvasToBlob } from "../../utils/canvasToBlob";
import { ControlsSidebar } from "./ControlsSidebar";
import { defaultOptions } from "../../model/Options";

const Container = tw.div`h-full flex`;

const ImagePane = tw.div`flex-grow flex-shrink flex items-center justify-center overflow-hidden p-2`;

const ImageContainer = tw.div`h-full w-full flex items-center justify-center`;

const Image = tw.div`flex-shrink-0 relative`;

const Layer = tw.div`absolute top-0 left-0`;

const ControlsPane = tw.div`bg-gray-300 flex flex-col`;

interface EditorPageProps {
  activity: Activity;
}

const EditorPage: React.FC<EditorPageProps> = ({ activity }) => {
  const [options, setOptions] = useState<typeof defaultOptions>(defaultOptions);

  const setOption = (key: string, value: string) => {
    setOptions({
      ...options,
      [key]: value,
    });
  };

  const layoutOptions: Layout =
    options.layout === "vertical" ? verticalLayout : squareLayout;

  const mapRef = useRef<MapRef>(null);
  const overlayCanvasRef = useRef<HTMLCanvasElement>(null);

  const { ref: containerRef, width = 1, height = 1 } = useResizeObserver();

  const scale = Math.min(
    width / layoutOptions.dimensions.width,
    height / layoutOptions.dimensions.height,
    1
  );

  const handleGetScreenshot = async () => {
    if (!mapRef.current || !overlayCanvasRef.current) return;

    const map = mapRef.current.getMap();
    const mapCanvas = map.getCanvas();

    const overlayCanvas = overlayCanvasRef.current;

    const combinedCanvas = document.createElement("canvas");
    combinedCanvas.width = layoutOptions.dimensions.width;
    combinedCanvas.height = layoutOptions.dimensions.height;

    const ctx = combinedCanvas.getContext("2d");
    if (!ctx) return;

    ctx.drawImage(mapCanvas, 0, 0);
    ctx.drawImage(overlayCanvas, 0, 0);

    const imageWindow = window.open("about:blank", "_blank");

    const blob = await canvasToBlob(combinedCanvas);
    const url = URL.createObjectURL(blob);

    imageWindow?.location.assign(url);
  };

  return (
    <Container>
      <ImagePane>
        <ImageContainer ref={containerRef}>
          <Image
            style={{
              transform: `scale(${scale})`,
              width: layoutOptions.dimensions.width,
              height: layoutOptions.dimensions.height,
            }}
          >
            <Layer>
              <Map
                activity={activity}
                options={options}
                layoutOptions={layoutOptions}
                mapRef={mapRef}
              />
            </Layer>
            <Layer>
              <Overlay
                activity={activity}
                options={options}
                layoutOptions={layoutOptions}
                canvasRef={overlayCanvasRef}
              />
            </Layer>
          </Image>
        </ImageContainer>
      </ImagePane>
      <ControlsPane>
        <ControlsSidebar options={options} setOption={setOption} />
        <div tw="border-t border-gray-500 p-2">
          <button
            onClick={handleGetScreenshot}
            tw="bg-orange-500 hover:bg-orange-600 text-white px-2 py-1 rounded w-full"
          >
            Save
          </button>
        </div>
      </ControlsPane>
    </Container>
  );
};

export default EditorPage;
