const readFile = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      if (!fileReader.result) return reject();

      resolve(fileReader.result.toString());
    };

    fileReader.onerror = () => {
      reject();
    };

    fileReader.readAsText(file);
  });

export default readFile;
