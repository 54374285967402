import React from "react";
import tw, { GlobalStyles as TailwindGlobalStyles } from "twin.macro";
import { createGlobalStyle } from "styled-components";

const AppGlobalStyles = createGlobalStyle`
  html, body, #root {
    ${tw`overscroll-none h-full overflow-hidden`}
    touch-action: none;
  }
`;

const GlobalStyles: React.FC = () => (
  <>
    <TailwindGlobalStyles />
    <AppGlobalStyles />
  </>
);

export default GlobalStyles;
