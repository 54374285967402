import React, { useEffect, useState } from "react";
import LandingPage from "./pages/landing/LandingPage";
import EditorPage from "./pages/editor/EditorPage";
import parseGpx from "./utils/parseGpx";
import GlobalStyles from "./GlobalStyles";
import { Activity } from "./model/Activity";
import { DEMO_FILES } from "./constants";

function App() {
  const [activity, setActivity] = useState<Activity>();

  const loadDemoFile = async (file: keyof typeof DEMO_FILES) => {
    const request = await fetch(DEMO_FILES[file]);
    const body = await request.text();

    const activity = parseGpx(body);

    if (!activity) return;

    setActivity(activity);
  };

  useEffect(() => {
    const file = window.location.search.slice(1);

    if (file in DEMO_FILES) loadDemoFile(file as keyof typeof DEMO_FILES);
  }, []);

  return (
    <>
      <GlobalStyles />
      {activity ? (
        <EditorPage activity={activity} />
      ) : (
        <LandingPage
          onActivityLoad={setActivity}
          onSelectDemoFile={loadDemoFile}
        />
      )}
    </>
  );
}

export default App;
